<template>
  <div class="page-item-wrap">
    <main class="sub __login">
      <div class="inner __login-wrap">
        <div class="tit req-type">
          <h1>로그인</h1>
          <p class="stit">호구153에 오신 것을 환영합니다!</p>
        </div>
        <div class="sub-cont">
          <div class="link-tab">
            <a
              href="javascript:void(0)"
              class="link TypeP"
              :class="{ active: loginType === 'P' }"
              @click="setLoginType('P')"
              >개인</a
            >
            <a
              href="javascript:void(0)"
              class="link"
              :class="{ active: loginType === 'E' }"
              @click="setLoginType('E')"
              >기업</a
            >
          </div>
          <div class="login-form-box">
            <input
              v-model="userId"
              type="text"
              class="text"
              placeholder="아이디"
              ref="userId"
            />
            <input
              v-model="userPw"
              type="password"
              class="text"
              placeholder="비밀번호"
              ref="userPw"
              @keyup.enter="login"
            />
            <p class="check-wrap">
              <input v-model="isSaveId" type="checkbox" id="c1" />
              <label for="c1">아이디 저장</label>
              <template v-if="isApp">
                <input v-model="isKeepLogin" type="checkbox" id="c2" />
                <label for="c2">로그인 유지</label>
              </template>
            </p>
            <button type="button" class="btn __login" @click="login">
              로그인
            </button>
            <div
              v-bind:class="{ on: checkMobile }"
              v-if="loginType === 'P'"
              class="sns-list"
            >
              <a href="#" @click="naverLogin">
                <img src="~assets/image/ico/ico_naver.png" alt="NAVER" />
                <span> 네이버로그인 </span>
              </a>
              <a href="#" @click="kakaoLogin">
                <img src="~assets/image/ico/ico_kakao.png" alt="KAKAO" />
                <span> 카카오로그인 </span>
              </a>
            </div>
          </div>

          <ul class="link-list">
            <li><router-link to="/find-id">아이디 찾기</router-link></li>
            <li><router-link to="/find-pw">비밀번호 찾기</router-link></li>
            <li><router-link to="/join">회원가입</router-link></li>
          </ul>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
const URL = process.env.VUE_APP_API_URL
import { mapMutations } from 'vuex'
import { postLoginPersonal, postLoginCompany, postFcmToken } from 'Api/modules'
import { authCheck, setAccessTokenToCookie } from 'Utils/auth'
import { getFCMToken, sendMessageToApp } from 'Utils/message'
import Cookies from 'js-cookie'

const LOGINTYPE = {
  PERSONAL: 'P',
  COMPANY: 'C',
}

export default {
  data() {
    return {
      loginType: LOGINTYPE.PERSONAL,
      userId: '',
      userPw: '',
      isSaveId: false,
      isKeepLogin: false,
      returnUrl: this.$route.query.returnUrl
        ? this.$route.query.returnUrl
        : '/',
    }
  },
  watch: {
    isSaveId(newVal) {
      if (newVal && this.userId !== '') Cookies.set('id', this.userId)
      else Cookies.remove('id')
    },
    // loginType() {
    //   if (this.returnUrl == '/regist-job') {
    //     // var loginthis = this
    //     this.loginType = 'E'
    //     window.$('.TypeP').click(function () {
    //       // loginthis.$toasted.error('일자리 등록은 기업회원 전용입니다.')
    //       console.log('a')
    //     })

    //     // alert(this.loginType)
    //   }
    // },
  },
  methods: {
    ...mapMutations(['SET_IS_LOGIN', 'SET_ACCOUNT_USER_ID', 'SET_FCM_TOKEN']),
    naverLogin() {
      if (this.isApp) {
        location.href = URL + '/oauth2/authorization/naver'
      } else {
        window.open(
          URL + '/oauth2/authorization/naver',
          'login',
          'width=600, height=800',
        )

        // 네이버 인증 후 인증 결과 메시지 수신 이벤트 핸들러
        window.addEventListener(
          'message',
          async msg => {
            //카카오 인증 결과 메시지인 경우
            if (
              msg.data.name === 'NAVER_REDIRECT' ||
              msg.data.name === 'LOGIN_REDIRECT'
            ) {
              const { result, userInfo, token } = msg.data
              //회원가입이 필요한 경우 회원가입 페이지로 props와 함께 전달
              if (result === 'JOIN') {
                const { id, email, name, gender, birthday, phoneNo } = userInfo
                let convertGenderVal = ''
                if (gender) {
                  convertGenderVal = gender === 'MALE' ? '0' : '1'
                }
                this.$router.push({
                  name: 'join',
                  params: {
                    pass: 'NAVER',
                    passId: id,
                    passName: name,
                    passEmail: email || '',
                    passGender: convertGenderVal,
                    passToken: token,
                    passPhoneNo: phoneNo ? phoneNo.replace(/-/gi, '') : '',
                    passBirthDay: birthday ? birthday.replace(/-/gi, '') : '',
                  },
                })
              }
              //이미 가입된 회원인 겨우 토큰 저장하고 메인 페이지로 이동
              else {
                this.processLogin(token)
              }
            }
            return
          },
          { once: true },
        )
      }
      // this.$toasted.show('(주)네이버와 심사가 예정되어 있습니다.')
    },
    kakaoLogin() {
      if (this.isApp) {
        location.href = URL + '/oauth2/authorization/kakao' //이동시켜서 인증 후 아래 created에서 query 처리한다.
      }
      //웹인 경우
      else {
        window.open(
          URL + '/oauth2/authorization/kakao',
          'login',
          'width=600, height=800',
        )

        // 카카오 인증 후 인증 결과 메시지 수신 이벤트 핸들러
        window.addEventListener(
          'message',
          async msg => {
            //카카오 인증 결과 메시지인 경우
            if (
              msg.data.name === 'KAKAO_REDIRECT' ||
              msg.data.name === 'LOGIN_REDIRECT'
            ) {
              const { result, userInfo, token } = msg.data

              //회원가입이 필요한 경우 회원가입 페이지로 props와 함께 전달
              if (result === 'JOIN') {
                const {
                  id,
                  email,
                  name,
                  gender,
                  birthyear,
                  birthday,
                  phone_number,
                } = userInfo

                let convertGenderVal = ''
                if (gender) {
                  convertGenderVal = gender === 'MALE' ? '0' : '1'
                }
                this.$router.push({
                  name: 'join',
                  params: {
                    pass: 'KAKAO',
                    passId: id,
                    passName: name,
                    passEmail: email || '',
                    passGender: convertGenderVal,
                    passToken: token,
                    passPhoneNo: phone_number
                      ? phone_number.replace(/\+82 /gi, '0')
                      : '',
                    passBirthDay: birthyear + birthday || '',
                  },
                })
              }
              //이미 가입된 회원인 겨우 토큰 저장하고 메인 페이지로 이동
              else {
                this.processLogin(token)
              }
            }
            return
          },
          { once: true },
        )
      }

      //location.href = URL + '/oauth2/authorization/kakao'
    },
    async getAndSetFcmToken() {
      if (this.isApp) {
        const fcmToken = await getFCMToken() //앱이 아닌 경우 '' 빈값을 수신한다.
        this.SET_FCM_TOKEN(fcmToken)
        return fcmToken
      }
      return ''
    },
    setLoginType(typeString) {
      this.loginType = typeString
      if (this.loginType == 'P') {
        if (this.returnUrl == '/regist-job') {
          var loginthis = this
          loginthis.$toasted.error('일자리 등록은 기업회원 전용입니다.')
        }
      }
    },
    async login() {
      // 아이디는 영문자/숫자 조함 5~20자
      if (!this.userId) {
        this.$toasted.error('아이디를 입력해주세요.')
        this.$refs['userId'].focus()
        return false
      }

      // 비밀번호 정합성 체크, 영문자/숫자/특수문자 2가지 아상 조합 8자리 이상
      if (!this.userPw) {
        this.$toasted.error('비밀번호를 입력해주세요.')
        this.$refs['userPw'].focus()
        return false
      }

      const fcmToken = await this.getAndSetFcmToken()

      try {
        let res = null

        //개인회원 로그인
        if (this.loginType === LOGINTYPE.PERSONAL) {
          res = await postLoginPersonal({
            userId: this.userId,
            userPw: this.userPw,
            loginType: this.loginType,
            fcmToken: fcmToken,
          })
        }
        //기업회원 로그인
        else {
          res = await postLoginCompany({
            userId: this.userId,
            userPw: this.userPw,
            loginType: this.loginType,
            fcmToken: fcmToken,
          })
        }

        //로그인 성공
        if (res.status === 0) {
          //아이디 저장 처리
          if (this.isSaveId) Cookies.set('id', this.userId)
          else Cookies.remove('id')

          //앱 로그인 유지 처리
          if (this.isApp) {
            //로그인 유지하도록 유저 정보를 앱으로 전송
            if (this.isKeepLogin) {
              sendMessageToApp('keepLogin', {
                userId: this.userId,
                userPw: this.userPw,
                loginType: this.loginType,
              })
            }
            //로그인 유지 제거하도록 앱에 전송
            else {
              //sendMessageToApp('clearKeepLogin')
            }
          }

          setAccessTokenToCookie(res.message)
          this.SET_ACCOUNT_USER_ID(this.userId)
          this.SET_IS_LOGIN(true)
          location.href = this.returnUrl
          // sendMessageToApp('LOG', {
          //   created: 'Login',
          //   isApp: Cookies.get('isApp'),
          //   isLogin: Cookies.get('isLogin'),
          //   thisIsLogin: this.isLogin,
          //   userId: Cookies.get('userId'),
          //   thisUserId: this.userId,
          // })
          //this.$router.push(this.returnUrl)
          if (
            this.loginType === LOGINTYPE.PERSONAL &&
            this.returnUrl == '/regist-job'
          ) {
            location.href = '/'
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    async processLogin(token) {
      if (!token) return
      token = decodeURI(token)
      setAccessTokenToCookie(token)

      const res = await authCheck()
      //console.log(res.userId)
      this.$store.commit('SET_IS_LOGIN', true)
      this.$store.commit('SET_ACCOUNT_INFO', res)

      //fcm token
      if (this.isApp) {
        try {
          const fcmToken = await this.getAndSetFcmToken()
          await postFcmToken({ fcmToken })
        } catch (e) {
          this.$toasted.error(e)
        }
        //로그인 유지하도록 유저 정보를 앱으로 전송
        sendMessageToApp('keepLogin', {
          userId: res.userId,
          userPw: res.userId,
          loginType: this.loginType,
        })
        this.SET_ACCOUNT_USER_ID(res.userId)
        this.SET_IS_LOGIN(true)
      }

      location.href = this.returnUrl
      //this.$router.push(this.returnUrl)
    },
  },
  computed: {
    checkMobile() {
      var varUA = navigator.userAgent.toLowerCase() //userAgent 값 얻기
      var mobile = /iphone|ipad|ipod|android/i.test(
        navigator.userAgent.toLowerCase(),
      )
      //console.log(mobile)
      if (mobile && varUA.indexOf('android') > -1) {
        //모바일 처리
        //alert('모바일')
        // alert('안드로이드')
        return false
      } else if (
        mobile &&
        (varUA.indexOf('iphone') > -1 ||
          varUA.indexOf('ipad') > -1 ||
          varUA.indexOf('ipod') > -1)
      ) {
        //alert('아이폰')
        return true
      } else {
        //alert('아이폰')
        //아이폰, 안드로이드 외
        return false
      }
    },
  },
  async created() {
    // alert(this.loginType)
    this.$nextTick(() => {
      if (Cookies.get('id')) {
        this.userId = Cookies.get('id')
        this.isSaveId = true
        this.$refs['userPw'].focus()
      } else {
        this.$refs['userId'].focus()
      }
    })
    console.info('returnUrl', this.returnUrl)
    if (this.returnUrl == '/regist-job') {
      this.loginType = 'E'
      // alert(this.loginType)
    }
    if (!this.$route.query) return
    //카카오/네이버 로그인으로 정상 새창 열려서 정상 로그인 되어 리디렉트 되는 경우 : 토큰 값을 부모창으로 전달한 후 닫는다.
    if (window.opener && !this.isApp) {
      window.opener.postMessage(
        {
          name: 'LOGIN_REDIRECT',
          result: 'LOGIN',
          token: this.$route.query.token
            ? decodeURI(this.$route.query.token)
            : '',
        },
        '*',
      )
      window.close()
    }
    //앱일 경우 (카카오로 이미 회원이어서 로그인 된 경우)
    //(회원가입 필요한 경우는 회원가입 페이지로 이동되므로, Personal에서 처리 해야함
    else if (this.isApp) {
      this.processLogin(this.$route.query.token)
    }
  },
}
</script>

<style></style>
